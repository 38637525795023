import React, { useMemo } from "react";
import { Container, Profile, Welcome, UserName } from "./styles";
import Toggle from "../Toggle";

/**
 * Para o toogle usaremos o react-switch
 * npm install react-switch
 * instrucões na pagina https://npmjs.com/package/react-switch
 */

const MainHeader: React.FC = () => {
  return (
    <Container>
      <UserName>React & React Native, Node e Laravel Developer</UserName>
    </Container>
  );
};
export default MainHeader;

/**
 *
 * <Profile>
        <Toggle />
        <Welcome>Olá, </Welcome>
        <UserName>Ronaldo Lamp</UserName>
    </Profile> 
 * 
 */
